<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>粉丝会员</el-breadcrumb-item>
          <el-breadcrumb-item>会员列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="会员等级">
              <el-select
                v-model="where.gxzVipCode"
                placeholder="请选择"
                clearable
                style="width: 100px"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="VIP1" value="VIP1"></el-option>
                <el-option label="VIP2" value="VIP2"></el-option>
                <el-option label="VIP3" value="VIP3"></el-option>
                <el-option label="VIP4" value="VIP4"></el-option>
                <el-option label="VIP5" value="VIP5"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="注册来源">
             <el-select v-model="where.registerType" placeholder="请选择" clearable style="width: 100px">
              <el-option label="全部" value=""></el-option>
              <el-option label="微信" value="1"></el-option>
              <el-option label="APP" value="3"></el-option>
             </el-select>
            </el-form-item>
            <el-form-item label="关键字">
              <el-input
                v-model="where.keyWord"
                placeholder="请输入手机号/昵称/openid"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="加入时间">
              <el-date-picker
                v-model="date"
                @change="getDate" @input="immediUpdate"
                type="daterange"
                range-separator="~"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                                start-placeholder="起始日期"
                end-placeholder="截止日期"
              >
                <i class="el-icon-date"></i>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                >条件筛选</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-button type="primary" icon="el-icon-plus" @click="add"
                >新增会员
              </el-button>
            </el-col>
            <el-col :span="12">
              <el-button type="primary" disabled icon="el-icon-tickets"
                >导出数据
              </el-button>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="admin_table_main">
        <div class="admin_table_main">
          <el-table :data="list" @selection-change="handleSelectionChange">
            <el-table-column prop="id" label="会员ＩＤ"></el-table-column>
            <!-- <el-table-column prop="payQrcodeNo" label="头像"> -->
              <!-- <template slot-scope="scope"> -->
                <!-- <el-avatar -->
                  <!-- :size="40" -->
                  <!-- :src="getIconUrl(scope.row.headImageUrl)" -->
                <!-- ></el-avatar> -->
              <!-- </template> -->
            <!-- </el-table-column> -->
            <el-table-column prop="nickName" label="昵称"></el-table-column>
            <el-table-column prop="mobile" label="会员账号"></el-table-column>
            <el-table-column prop="registerType" label="注册来源">
              <template slot-scope="scope">
                <el-link type="primary" v-if="scope.row.registerType == 1"
                  >微信</el-link
                >
                <el-link type="success" v-if="scope.row.registerType == 2"
                  >小程序</el-link
                >
                <el-link type="warning" v-if="scope.row.registerType == 3"
                  >app</el-link
                >
                <el-link type="danger" v-if="scope.row.registerType == 4"
                  >PC</el-link
                >
              </template>
            </el-table-column>
            <el-table-column prop="alipayId" label="支付宝ID"></el-table-column>
            <el-table-column prop="openId" label="微信ID"></el-table-column>
            <el-table-column prop="gxzVipCode" label="会员等级"></el-table-column>
            <el-table-column
              prop="createDate"
              label="加入时间"
            ></el-table-column>
            <el-table-column prop="state" label="状态">
              <template slot-scope="scope">
                <el-link type="primary" v-if="scope.row.state == 1"
                  >启用</el-link
                >
                <el-link type="info" v-if="scope.row.state == 0">禁用</el-link>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="100px">
              <template slot-scope="scope">
                <el-button
                  type="success"
                  @click="detail(scope.row)"
                  icon="el-icon-tickets"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="admin_table_main_pagination">
            <el-pagination
              @current-change="currentChange"
              @size-change="handleSizeChange"
              background
              :total="totalData"
              :page-size="pageSize"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              layout="sizes, prev, pager, next, total, jumper"
            ></el-pagination>
          </div>
        </div>
        <batch
          @dialog="getDialog"
          :qrcodeData="qrcodeData"
          v-if="dialogBatchVisible"
        ></batch>
      </div>
    </div>
  </div>
</template>
<script>
import batch from "@/components/admin/payment_code/batch.vue";
import { staticUrl } from "@/plugins/api";

export default {
  components: {
    batch,
  },
  props: {},
  data() {
    return {
      list: [],
      dialogBatchVisible: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      selectId: "",
      goods_verify_count: 0,
      date: "",
      where: {
        isExchangeOrder: "",
        keyWord: "",
        gxzVipCode :"",
        pageNum: 1,
        pageSize: 10,
        registerType: "",
      },
      qrcodeData: {},
    };
  },
  methods: {
    listData() {
      this.where.pageSize = this.pageSize;
      this.where.pageNum = this.currentPage;
      //payCodeBatchList
      this.$post(this.$api.membershipList, this.where).then((res) => {
        if (res.code == 1) {
          this.totalData = res.data.total;
          this.list = res.data.records;
        }
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v);
      });
      this.selectId = ids;
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    getDate(obj) {
      if (obj) {
        this.where.createDateStart = obj[0];
        this.where.createDateEnd = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.createDateStart = '';
          this.where.createDateEnd = '';
        }
      });
    },
    getDialog(val) {
      this.dialogBatchVisible = val;
    },
    batchDialog() {
      this.dialogBatchVisible = true;
    },
    getIconUrl(val) {
      return staticUrl + val;
    },
    add() {
      this.$router.push({ name: "membersAdd" });
    },
    detail(data) {
      this.$router.push({ name: "membersDetail", query: data });
    },
  },
  created() {
    this.listData();
  },
};
</script>
